.card {
  border: 1px solid white;
  color: #fff;
  font-size: 20px;
  padding: 20px;
  margin: 10px 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: radial-gradient(black, transparent);
}
.img {
  display: flex;
  justify-content: center;
  align-items: center;
}
